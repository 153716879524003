import React, { useEffect, useRef } from "react"
import styled from "styled-components"

type IconProps = {
  icon: string
  className?: string
  style?: StyleSheet
}

const Wrapper = styled.span`
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  z-index: 5;

  path:not(.ionicon-fill-none),
  circle:not(.ionicon-fill-none) {
    fill: currentColor;
  }

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  .ionicon-fill-none {
    fill: none;
    stroke: currentColor;
    stroke-width: 32px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .ionicon-stroke-width {
    stroke: currentColor;
    stroke-width: 32px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`

const Icon = ({ icon, className, style }: IconProps) => {
  const WrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (WrapperRef.current)
      WrapperRef.current.innerHTML = icon.replace(
        "data:image/svg+xml;utf8,",
        ""
      )
  }, [icon])
  return <Wrapper ref={WrapperRef} className={className} style={style} />
}

export default Icon

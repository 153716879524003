import React, { Component } from "react"
import breakpoints from "theme/breakpoints"
import { fromEvent, Subscription } from "rxjs"

interface Media {
  [key: string]: boolean
}

export interface ViewContextInterface {
  isMenuOpened: boolean // mobile sized (sn, md)
  setMenuOpened(value: boolean): void
  media: Media
}

interface WithViewContextInterface {
  viewContext: ViewContextInterface
}

const {
  Provider,
  Consumer: ViewContextConsumer,
} = React.createContext<ViewContextInterface | null>(null)

const checkMedia = (width: number): Media => {
  const keys = Object.keys(breakpoints)
  const values = Object.values(breakpoints)
  const length = keys.length
  const media: Media = {}

  for (let i = 0; i < length - 1; i++) {
    media[keys[i]] = width >= values[i] && width < values[i + 1]
  }
  media[keys[length - 1]] = width >= values[length - 1]
  return media
}

class ViewContextProvider extends Component<{}, ViewContextInterface> {
  private subscription!: Subscription

  constructor(props: {}) {
    super(props)
    this.state = {
      isMenuOpened: false,
      setMenuOpened: value => this.setState({ isMenuOpened: value }),
      media: checkMedia(0),
    }
  }

  componentDidMount() {
    if (window) {
      this.setState({ media: checkMedia(window.innerWidth) })
      this.subscription = fromEvent(window, "resize").subscribe(() => {
        this.setState({ media: checkMedia(window.innerWidth) })
      })
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

const withViewContext = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof WithViewContextInterface>> => props => (
  <ViewContextConsumer>
    {value => <Component {...(props as P)} viewContext={value} />}
  </ViewContextConsumer>
)

export { ViewContextProvider, ViewContextConsumer, withViewContext }

import { createGlobalStyle, ThemeProvider } from "styled-components"
import React, { FC } from "react"
import bariolSerifRegularEot from "./fonts/bariol_serif_regular-webfont.eot"
import bariolSerifRegularWoff2 from "./fonts/bariol_serif_regular-webfont.woff2"
import bariolSerifRegularWoff from "./fonts/bariol_serif_regular-webfont.woff"
import bariolSerifRegularTtf from "./fonts/bariol_serif_regular-webfont.ttf"
import bariolSerifRegularSvg from "./fonts/bariol_serif_regular-webfont.svg"
import bariolSerifRegularItalicEot from "./fonts/bariol_serif_regular_italic-webfont.eot"
import bariolSerifRegularItalicWoff2 from "./fonts/bariol_serif_regular_italic-webfont.woff2"
import bariolSerifRegularItalicWoff from "./fonts/bariol_serif_regular_italic-webfont.woff"
import bariolSerifRegularItalicTtf from "./fonts/bariol_serif_regular_italic-webfont.ttf"
import bariolSerifRegularItalicSvg from "./fonts/bariol_serif_regular_italic-webfont.svg"
import bariolSerifBoldEot from "./fonts/bariol_serif_bold-webfont.eot"
import bariolSerifBoldWoff2 from "./fonts/bariol_serif_bold-webfont.woff2"
import bariolSerifBoldWoff from "./fonts/bariol_serif_bold-webfont.woff"
import bariolSerifBoldTtf from "./fonts/bariol_serif_bold-webfont.ttf"
import bariolSerifBoldSvg from "./fonts/bariol_serif_bold-webfont.svg"
import bariolSerifBoldItalicEot from "./fonts/bariol_serif_bold_italic-webfont.eot"
import bariolSerifBoldItalicWoff2 from "./fonts/bariol_serif_bold_italic-webfont.woff2"
import bariolSerifBoldItalicWoff from "./fonts/bariol_serif_bold_italic-webfont.woff"
import bariolSerifBoldItalicTtf from "./fonts/bariol_serif_bold_italic-webfont.ttf"
import bariolSerifBoldItalicSvg from "./fonts/bariol_serif_bold_italic-webfont.svg"
import breakpoints from "./breakpoints"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Bariol Serif';
    src: url(${bariolSerifRegularEot});
    src: url(${bariolSerifRegularEot}?#iefix) format('embedded-opentype'),
         url(${bariolSerifRegularWoff2}) format('woff2'),
         url(${bariolSerifRegularWoff}) format('woff'),
         url(${bariolSerifRegularTtf}) format('truetype'),
         url(${bariolSerifRegularSvg}#bariol_serifregular) format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Bariol Serif';
    src: url(${bariolSerifRegularItalicEot});
    src: url(${bariolSerifRegularItalicEot}?#iefix) format('embedded-opentype'),
         url(${bariolSerifRegularItalicWoff2}) format('woff2'),
         url(${bariolSerifRegularItalicWoff}) format('woff'),
         url(${bariolSerifRegularItalicTtf}) format('truetype'),
         url(${bariolSerifRegularItalicSvg}#bariol_serifregular) format('svg');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Bariol Serif';
    src: url(${bariolSerifBoldEot});
    src: url(${bariolSerifBoldEot}?#iefix) format('embedded-opentype'),
         url(${bariolSerifBoldWoff2}) format('woff2'),
         url(${bariolSerifBoldWoff}) format('woff'),
         url(${bariolSerifBoldTtf}) format('truetype'),
         url(${bariolSerifBoldSvg}#bariol_serifregular) format('svg');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Bariol Serif';
    src: url(${bariolSerifBoldItalicEot});
    src: url(${bariolSerifBoldItalicEot}?#iefix) format('embedded-opentype'),
         url(${bariolSerifBoldItalicWoff2}) format('woff2'),
         url(${bariolSerifBoldItalicWoff}) format('woff'),
         url(${bariolSerifBoldItalicTtf}) format('truetype'),
         url(${bariolSerifBoldItalicSvg}#bariol_serifregular) format('svg');
    font-weight: bold;
    font-style: italic;
  }
  
  body {
    font-family: 'Bariol Serif', serif;
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }
  
  * {
    box-sizing: border-box;
    font-family: 'Bariol Serif';
  }
  
  p {
    line-height: 1.5;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  ol {
    padding-left: 1.5em;
    margin: 0;
    line-height: 1.5;
    list-style: none;
    counter-reset: li;
    
    li {
      ::before {
        counter-increment: li;
        content: counter(li) "."; 
        color: ${props => props.theme.color.primary};
        display: inline-block;
        width: 1.5em;
        margin-left: -1.5em
        }
    }
    
  }
  
  li {
    margin: 0;
  }

  .page {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
  }

  .fragment {
    background-color: #fff;
    position: relative
  }

  .fade-enter > .page, .fade-enter > .fragment {
    opacity: 0;
    z-index: 100;
  }

  .fade-enter.fade-enter-active > .page, .fade-enter.fade-enter-active > .fragment {
    opacity: 1;
    transition: opacity 100ms;
  }

  a {
    color: ${props => props.theme.color.black};

  }
  
  .react-photo-gallery--gallery img:hover {
    filter: brightness(120%);
  }
`

export const theme = {
  color: {
    primary: "#FFC200",
    primaryLight: "#FFEDB2",
    primaryLighter: "#FFF6D8",
    primaryText: "#EEB500",
    primaryTextDarker: "#D7BE6C",
    white: "#fff",
    black: "#000",
    dark: "#777",
    muted: "#9E9E9E",
    grayLight: "#F4F4F4",
    error: "#D22",
    success: "#45a745",
  },
  media: {
    sm: `@media (min-width: ${breakpoints.sm}px)`,
    md: `@media (min-width: ${breakpoints.md}px)`,
    lg: `@media (min-width: ${breakpoints.lg}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`,
  },
}

const Theme: FC = props => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {props.children}
    </ThemeProvider>
  )
}

export default Theme
